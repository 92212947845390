<script setup lang="ts">
const userStore = useUserStore()
</script>

<template>
    <div class="page-query-abilities">
        <RichText
            class="page-query-abilities__text rich-text--compact"
            :text="$t('page.queryAbilities.introText')"
        />

        <ButtonGroup class="page-query-abilities__buttons">
            <AppButton
                fluid
                @click="
                    () => {
                        userStore.canTakeStairs = true
                        $qrHuntRouter.push('overview')
                    }
                "
            >
                {{ $t('page.queryAbilities.buttonLabel.yes') }}
            </AppButton>

            <AppButton
                fluid
                variant="ghost"
                @click="
                    () => {
                        userStore.canTakeStairs = false
                        $qrHuntRouter.push('overview')
                    }
                "
            >
                {{ $t('page.queryAbilities.buttonLabel.no') }}
            </AppButton>
        </ButtonGroup>
    </div>
</template>

<style lang="scss">
.page-query-abilities {
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(86px, 1fr) auto;
    grid-template-areas:
        'text   '
        '.      '
        'buttons';

    &__text {
        grid-area: text;
    }

    &__buttons {
        grid-area: buttons;
    }
}
</style>
